<template>
  <section class="section" id="relatorio-entrega-ingressos">
    <div class="container-admin">
      <div class="columns is-multiline">
        <div class="column is-full">
          <h3 class="title is-3 has-text-primary">
            Relatório de Entrega de Ingressos
          </h3>
        </div>
        <div class="column is-one-fifth">
          <b-field label="Data Inicial">
            <b-input type="date" v-model="filtro.dataI" />
          </b-field>
        </div>
        <div class="column is-one-fifth">
          <b-field label="Data Final">
            <b-input type="date" v-model="filtro.dataF" />
          </b-field>
        </div>
        <div class="column is-one-fifth">
          <b-field label="Venda Id">
            <b-input type="number" v-model="filtro.vendaId" step="1" />
          </b-field>
        </div>
        <div class="column is-one-fifth">
          <b-field label="Retirado">
            <b-radio-button v-model="filtro.retirado" :native-value="true" type="is-success">
              <span>Sim</span>
            </b-radio-button>

            <b-radio-button v-model="filtro.retirado" :native-value="false" type="is-danger">
              <span>Não</span>
            </b-radio-button>
          </b-field>
        </div>
        <div class="column is-one-fifth" style="display: flex;align-items: flex-end;">
          <b-button type="is-secondary" expanded :disabled="!filtro.dataI || !filtro.dataF" @click="buscarVendas">
            Buscar
          </b-button>
        </div>
      </div>
      <hr />

      <b-collapse class="card card-summary" animation="slide" v-for="(itemResumo, index) in resumo" :key="index">
        <template #trigger="props">
          <div class="card-header" role="button">
            <p class="card-header-title has-text-centered has-text-primary" style="justify-content: center">
              {{ itemResumo.parqueNome }}
            </p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
            </a>
          </div>
        </template>
        <div class="card-content">
          <div class="content">
            <div class="columns">
              <div class="column">
                <div class="card card-valores">
                  <div class="columns is-multiline">
                    <div class="column is-one-third has-text-centered">
                      <h5 class="title is-5">Total Inteira</h5>
                      <p class="is-size-2 header-dia-semana">
                        {{ itemResumo.totalIngressosInteiraSemana }}
                      </p>
                    </div>
                    <div class="column is-one-third has-text-centered">
                      <h5 class="title is-5">Total Meia</h5>
                      <p class="is-size-2 header-dia-semana">
                        {{ itemResumo.totalIngressosMeiaSemana }}
                      </p>
                    </div>
                    <div class="column is-one-third has-text-centered">
                      <h5 class="title is-5">Total Infantil</h5>
                      <p class="is-size-2 header-dia-semana">
                        {{ itemResumo.totalIngressosInfantilSemana }}
                      </p>
                    </div>
                    <div class="column is-full has-text-centered p-0">
                      Segunda a Sexta
                    </div>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="card card-valores">
                  <div class="columns is-multiline">
                    <div class="column is-one-third has-text-centered">
                      <h5 class="title is-5">Total Inteira</h5>
                      <p class="is-size-2 header-final-de-semana">
                        {{ itemResumo.totalIngressosInteiraFimDeSemana }}
                      </p>
                    </div>
                    <div class="column is-one-third has-text-centered">
                      <h5 class="title is-5">Total Meia</h5>
                      <p class="is-size-2 header-final-de-semana">
                        {{ itemResumo.totalIngressosMeiaFimDeSemana }}
                      </p>
                    </div>
                    <div class="column is-one-third has-text-centered">
                      <h5 class="title is-5">Total Infantil</h5>
                      <p class="is-size-2 header-final-de-semana">
                        {{ itemResumo.totalIngressosInfantilFimDeSemana }}
                      </p>
                    </div>
                    <div class="column is-full has-text-centered p-0">
                      Sábado e Domingo
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>

      <section v-if="vendas.length === 0 && !isLoading" class="section has-background-light has-text-centered" style="border-radius: 10px">
        Nenhum resultado encontrado para a sua busca.
      </section>
      <div v-else v-for="vendasAgrupadas in agrupamentoVendas" :key="vendasAgrupadas.data">
        <hr />
        <div class="header-sales-group">
          <div class="date-baloon">
            <h5 class="title is-5">{{ vendasAgrupadas.data | maskDate }}</h5>
          </div>

          <b-table :data="vendasAgrupadas.vendas" :loading="isLoading" hoverable mobile-cards detailed detail-key="vendaId" show-detail-icon>
            <b-table-column field="vendaId" label="ID" centered v-slot="props">
              {{ props.row.vendaId }}
            </b-table-column>

            <b-table-column field="nome" label="Nome" centered v-slot="props">
              {{ props.row.nome }}
            </b-table-column>

            <b-table-column field="inteira" label="Qtd. Inteira" custom-key="Qtd. Inteira Semana" centered number>
              <template v-slot:header="{ column }">
                {{ column.label }}
                <br />
                <small class="header-dia-semana">Dia de Semana</small>
              </template>
              <template v-slot="props">
                {{ props.row.inteira || 0 }}
              </template>
            </b-table-column>

            <b-table-column field="meia" label="Qtd. Meia" custom-key="Qtd. Meia Semana" centered number>
              <template v-slot:header="{ column }">
                {{ column.label }}
                <br />
                <small class="header-dia-semana">Dia de Semana</small>
              </template>
              <template v-slot="props">
                {{ props.row.meia || 0 }}
              </template>
            </b-table-column>

            <b-table-column field="infantil" label="Qtd. Infantil" custom-key="Qtd. Infantil Semana" centered number>
              <template v-slot:header="{ column }">
                {{ column.label }}
                <br />
                <small class="header-dia-semana">Dia de Semana</small>
              </template>
              <template v-slot="props">
                {{ props.row.infantil || 0 }}
              </template>
            </b-table-column>

            <b-table-column field="inteiraFimDeSemana" label="Qtd. Inteira" custom-key="Qtd. Inteira Fim de Semana" centered number>
              <template v-slot:header="{ column }">
                {{ column.label }}
                <br />
                <small class="header-final-de-semana">Fim de Semana</small>
              </template>
              <template v-slot="props">
                {{ props.row.inteiraFimDeSemana || 0 }}
              </template>
            </b-table-column>

            <b-table-column field="meiaFimDeSemana" label="Qtd. Meia" custom-key="Qtd. Meia Fim de Semana" centered number>
              <template v-slot:header="{ column }">
                {{ column.label }}
                <br />
                <small class="header-final-de-semana">Fim de Semana</small>
              </template>
              <template v-slot="props">
                {{ props.row.meiaFimDeSemana || 0 }}
              </template>
            </b-table-column>

            <b-table-column field="infantilFimDeSemana" label="Qtd. Infantil" custom-key="Qtd. Infantil Fim de Semana" centered number>
              <template v-slot:header="{ column }">
                {{ column.label }}
                <br />
                <small class="header-final-de-semana">Fim de Semana</small>
              </template>
              <template v-slot="props">
                {{ props.row.infantilFimDeSemana || 0 }}
              </template>
            </b-table-column>

            <b-table-column field="acoes" label="Ações" centered v-slot="props">
              <b-button class="is-info mr-2" @click="imprimirVenda(props.row)">
                Imprimir
              </b-button>
              <b-button v-if="!props.row.entregue" class="is-info" @click="confirmarEntrega(props.row)">
                Confirmar Entrega
              </b-button>
              <b-button v-else type="is-success" style="pointer-events: none;">
                Entrega Realizada
              </b-button>

              <b-button style="margin-left: 10px" v-if="!props.row.entregue" type="is-primary" @click="enviarmsg(props.row)">
                Enviar WhatsApp
              </b-button>
            </b-table-column>

            <template slot="detail" slot-scope="props">
              <b-table :data="props.row.detalhes" :loading="isLoading" hoverable mobile-cards>
                <b-table-column field="tipo" label="Tipo" centered v-slot="props">
                  <span class="is-capitalized">
                    {{ props.row.tipo }}
                  </span>
                </b-table-column>

                <b-table-column field="parqueId" label="Parque" centered v-slot="props">
                  <span class="is-capitalized">
                    {{ findLabelParque(props.row.parqueId) }}
                  </span>
                </b-table-column>

                <b-table-column field="quantidade" label="Quantidade" numeric centered v-slot="props">
                  {{ props.row.quantidade }}
                </b-table-column>

                <b-table-column field="data" label="Data" centered v-slot="props">
                  {{ props.row.data | maskDate }}
                </b-table-column>
              </b-table>
            </template>
          </b-table>
        </div>
      </div>
      <ImprimirVenda :vendaId="vendaIdImpressao" :ativo="isModalImprimirVendaAtivo" @cancel="fecharModalVenda" />
      <ModalConfirmarEntrega
        :ativo="modalConfirmarEntregaAtivo"
        :venda="vendaObj"
        @cancel="cancelarEntregaIngressos"
        @confirm="confirmarEntregaDeIngresos"
      />
    </div>
  </section>
</template>

<script>
import ModalConfirmarEntrega from '@/components/Relatorios/Entrega/ModalConfirmarEntrega.vue';
import ImprimirVenda from '@/components/Relatorios/Entrega/ImprimirVenda.vue';
import vendaService from '@/services/vendaService';
import parqueService from '@/services/parqueService';
import { formatDateToInput } from '@/helpers/helpers';

export default {
  name: 'RelatorioDeEntregaIngressos',
  components: {
    ModalConfirmarEntrega,
    ImprimirVenda,
  },
  data() {
    return {
      isLoading: false,
      filtro: {
        dataI: formatDateToInput(new Date()),
        dataF: formatDateToInput(new Date().addDays(7)),
        retirado: false,
        vendaId: '',
      },
      parques: [],
      vendas: [],
      modalConfirmarEntregaAtivo: false,
      vendaObj: null,
      confirmVendaId: null,
      resumo: {},
      isResumoOpen: 0,
      isModalImprimirVendaAtivo: false,
      vendaIdImpressao: null,
    };
  },
  computed: {
    agrupamentoVendas() {
      if (this.vendas.length > 0) {
        let arrayDatas = [...new Set(this.vendas.map(x => x.data))];

        const agrupamento = [];

        arrayDatas.forEach(data => {
          const vendasPorData = this.vendas.filter(v => v.data === data);

          if (vendasPorData.length > 0) {
            agrupamento.push({
              data,
              vendas: vendasPorData,
            });
          }
        });

        return agrupamento;
      }

      return [];
    },
  },
  async created() {
    await this.getParques();
    this.buscarVendas();
  },
  methods: {
    async getParques() {
      return parqueService.get().then(({ data }) => {
        this.parques = data;
        const [parque] = data;
        if (parque) {
          this.filtro.parqueId = parque.id;
        }
      });
    },
    findLabelParque(parqueId) {
      return this.parques.find(p => p.id === parqueId)?.nome;
    },
    getRelatorioEntregaIngressos() {
      return vendaService.getRelatorioEntregaIngressos(this.filtro).then(({ data: response }) => {
        this.vendas = response;
        this.calcularTotais(response);
      });
    },
    enviarmsg(venda) {
      const telefone = '55' + venda.telefone;
      const msg =
        'Oi, tudo bem?\n Somos da agência Olimpia Parques.\n' +
        ' Estamos entrando em contato para lembrar que o local de retirada dos ingresso é no HOTEL SAVANA, rua David de Oliveira, 337.\n' +
        ' Horário de Retirada: 8h as 11h. \n' +
        ' Localização: \n' +
        ' https://bityli.com/GkVCJDm \n ' +
        ' Atenciosamente Equipe Olimpia Parques. \n';

      let url = 'https://api.whatsapp.com/send?phone=' + telefone + '&text=' + msg;

      window.open(url, '_blank');
    },
    buscarVendas() {
      this.getRelatorioEntregaIngressos();
    },
    calcularTotais(vendas) {
      const parquesIds = [...new Set(vendas.flatMap(v => v.detalhes).map(p => p.parqueId))];

      const detalhes = vendas.flatMap(v => v.detalhes);

      const reduceFunction = (key, parqueId) => {
        const arrayByPark = detalhes.filter(d => {
          const indexDay = new Date(d.data).getDay();
          const isWeekend = indexDay === 0 || indexDay === 6;
          return d.parqueId === parqueId && !isWeekend && d.tipo === key;
        });

        const array = arrayByPark.map(x => Number(x.quantidade));
        return array.reduce((acc, number) => acc + number, 0);
      };

      const reduceFunctionWeekend = (key, parqueId) => {
        const arrayByPark = detalhes.filter(d => {
          const indexDay = new Date(d.data).getDay();
          const isWeekend = indexDay === 0 || indexDay === 6;
          return d.parqueId === parqueId && isWeekend && d.tipo === key;
        });

        const array = arrayByPark.map(x => Number(x.quantidade));
        return array.reduce((acc, number) => acc + number, 0);
      };

      this.resumo = parquesIds.map(parqueId => {
        const parque = this.parques.find(p => p.id === parqueId);

        return {
          parqueId: parqueId,
          parqueNome: parque.nome,
          totalIngressosInteiraSemana: reduceFunction('inteira', parqueId),
          totalIngressosMeiaSemana: reduceFunction('meia', parqueId),
          totalIngressosInfantilSemana: reduceFunction('infantil', parqueId),
          totalIngressosInteiraFimDeSemana: reduceFunctionWeekend('inteira', parqueId),
          totalIngressosMeiaFimDeSemana: reduceFunctionWeekend('meia', parqueId),
          totalIngressosInfantilFimDeSemana: reduceFunctionWeekend('infantil', parqueId),
        };
      });
    },
    confirmarEntrega(venda) {
      this.vendaObj = venda;
      this.modalConfirmarEntregaAtivo = true;
    },
    cancelarEntregaIngressos() {
      this.modalConfirmarEntregaAtivo = false;
      this.vendaObj = null;
    },
    confirmarEntregaDeIngresos(form) {
      vendaService
        .confirmarEntrega(this.vendaObj.vendaId, form)
        .then(() => {
          this.vendaObj.entregue = true;
          this.vendaObj = null;
          this.modalConfirmarEntregaAtivo = false;
          this.notificar('Entrega realizada com sucesso', 'is-success');
          this.buscarVendas();
        })
        .catch(() => {
          this.notificar('Acesso negado', 'is-danger');
        });
    },
    notificar(message, type, position = 'is-bottom-right') {
      this.$buefy.snackbar.open({
        message,
        type,
        position,
        actionText: 'OK',
        queue: false,
        duration: 3000,
      });
    },
    imprimirVenda({ vendaId }) {
      this.vendaIdImpressao = vendaId;
      this.isModalImprimirVendaAtivo = true;
    },
    fecharModalVenda() {
      this.isModalImprimirVendaAtivo = false;
    },
  },
};
</script>

<style lang="scss">
#relatorio-entrega-ingressos {
  .date-baloon {
    display: inline-block;
    background-color: #ff6d01;
    width: 200px;
    padding: 18px;
    border-radius: 15pc;
    text-align: center;
    margin-bottom: 20px;
    margin-right: 10px;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
    h5 {
      color: #fff;
    }
  }

  .park-name-baloon {
    /* display: inline-block; */
    background-color: #ff6d01;
    width: 100%;
    padding: 10px;
    border-radius: 15pc;
    text-align: center;
    margin-bottom: 20px;
    margin-right: 10px;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);

    &:not(:first-child) {
      margin-top: 20px;
    }
    h5 {
      color: #fff;
    }
  }
  .header-final-de-semana {
    color: #fe6060;
  }
  .header-dia-semana {
    color: #6767ff;
  }

  .b-table {
    .table tr.detail {
      background-color: whitesmoke;
      .detail-container {
        padding: 0;
        .table {
          background-color: whitesmoke;
        }
      }
    }

    td {
      vertical-align: middle;
    }
  }

  .card-valores {
    border-radius: 10px;
    padding: 2rem 0;
    h5 {
      margin-bottom: 5px;
    }
  }

  .card-summary {
    .card-content {
      background-color: #467da48f;
    }
  }
}
</style>
