<template>
  <b-modal :active.sync="modalAtivo" has-modal-card>
    <div
      class="modal-card"
      v-if="vendaId"
      id="card-venda"
      style="min-width: 980px"
    >
      <header class="modal-card-head">
        <p class="modal-card-title">
          <b>Imprimir Venda</b>
        </p>
        <button type="button" class="delete" @click="$emit('cancel')" />
      </header>
      <div class="modal-card-body">
        <AcompanhamentoPedido :id="vendaId" />
      </div>
      <footer class="modal-card-foot">
        <b-button label="Cancelar" @click="$emit('cancel')" />
      </footer>
    </div>
  </b-modal>
</template>

<script>
import AcompanhamentoPedido from '@/components/Pedido/AcompanhamentoPedido.vue';

export default {
  components: { AcompanhamentoPedido },
  name: 'ImprimirVenda',
  props: {
    vendaId: {
      type: [String, Number],
      default: null,
    },
    ativo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalAtivo: false,
    };
  },
  watch: {
    ativo(novoValor) {
      this.modalAtivo = novoValor;
    },
    modalAtivo(novoValor) {
      if (!novoValor) {
        this.cancel();
      }
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>
