<template>
  <b-modal :active.sync="modalAtivo" has-modal-card>
    <form class="modal-card" @submit="confirm">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <b>Confirmar Entrega</b>
        </p>
      </header>
      <section class="modal-card-body">
        <b-message title="Atenção" type="is-danger" aria-close-label="Fechar">
          Revise os ingressos do cliente antes de confirmar a venda
        </b-message>
        <section style="padding: 0.5em 0.5em 2em 0.5em">
          <h5 class="title is-5 has-text-centered has-text-primary">
            Ingressos
          </h5>
          <b-table :data="ingressosAgrupados">
            <b-table-column field="data" label="Data" v-slot="props">
              {{ props.row.data | maskDate }}
            </b-table-column>
            <b-table-column
              cell-class="is-capitalized"
              field="tipo"
              label="Tipo"
              v-slot="props"
              centered
            >
              {{ props.row.tipo }}
            </b-table-column>
            <b-table-column
              field="quantidade"
              label="Quantidade"
              v-slot="props"
              centered
            >
              {{ props.row.quantidade }}
            </b-table-column>
          </b-table>
        </section>
        <div class="columns">
          <div class="column">
            <b-field label="Digite sua senha">
              <b-input
                type="password"
                name="password"
                password-reveal
                v-model="form.password"
              />
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" name="cancelar" @click="cancel">
          Cancelar
        </button>
        <button
          class="button is-primary"
          style="float: right"
          name="confirmar"
          type="submit"
        >
          Confirmar
        </button>
      </footer>
    </form>
  </b-modal>
</template>

<script>
import vendaService from '@/services/vendaService';

export default {
  name: 'ModalConfirmarEntrega',
  props: {
    ativo: {
      type: Boolean,
      default: false,
    },
    venda: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      modalAtivo: false,
      form: {},
      vendaObject: null,
    };
  },
  watch: {
    ativo(novoValor) {
      this.modalAtivo = novoValor;
      if (novoValor) {
        this.getVendaDetalhe();
      }
    },
    modalAtivo(novoValor) {
      if (!novoValor) {
        this.cancel();
      }
    },
  },
  computed: {
    ingressosAgrupados() {
      if (!this.vendaObject) return [];

      try {
        const { detalhes } = this.vendaObject;
        const datas = [...new Set(detalhes.map(d => d.data))];
        const agrupamento = [];

        datas.forEach(data => {
          const detalheMesmaData = detalhes.filter(d => d.data === data);
          const tipos = [...new Set(detalheMesmaData.map(d => d.tipo))];

          tipos.forEach(tipo => {
            const detalhesMesmoTipoEData = detalheMesmaData.filter(
              d => d.tipo === tipo,
            );

            agrupamento.push({
              data,
              tipo,
              quantidade: detalhesMesmoTipoEData.length,
            });
          });
        });

        return agrupamento;
      } catch (error) {
        return [];
      }
    },
  },
  methods: {
    cancel() {
      this.vendaObject = {};
      this.$emit('cancel');
    },
    confirm(event) {
      event.preventDefault();
      this.$emit('confirm', { ...this.form });
      this.form = {};
      //this.vendaObject = {};
    },
    getVendaDetalhe() {
      if (this.venda?.vendaId) {
        vendaService.getById(this.venda.vendaId).then(({ data }) => {
          this.vendaObject = data;
        });
      }
    },
  },
};
</script>
