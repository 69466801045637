<template>
  <div id="print-acompanhamento-pedido">
    <img src="@/assets/logo.350.png" class="logo-image" alt="Logo Olimpia Parques" />
    <h3>
      Pedido de Compra de Ingressos
    </h3>
    <h4>Pedido #{{ numeroPedido }}</h4>
    <div class="container-dados">
      <table>
        <tr>
          <th><strong>Nome</strong></th>
          <th><strong>CPF</strong></th>
          <th><strong>Telefone</strong></th>
          <th><strong>Status Pagamento</strong></th>
        </tr>
        <tr>
          <td>
            <span>{{ dados.nome }}</span>
          </td>
          <td>
            <span> {{ dados.cpf }}</span>
          </td>
          <td>
            <span> {{ dados.telefone }}</span>
          </td>
          <td>
            <span>
              {{ dados.pago ? 'Pago' : 'Não Realizado' }}
            </span>
          </td>
        </tr>
      </table>

      <div v-if="dados.pago">
        <h3>Pagamento</h3>
        <table v-if="dados.pago">
          <tr>
            <th><strong>Forma de pagamento</strong></th>
            <th><strong>Nome Cartão</strong></th>
            <th><strong>Número Cartão</strong></th>
            <th><strong>Código</strong></th>
          </tr>
          <tr>
            <td>
              <span>{{ dados.formaPagamento }}</span>
            </td>
            <td>
              <span>{{ dados.nomeCartao }}</span>
            </td>
            <td>
              <span>{{ dados.numeroCartao }}</span>
            </td>
            <td>
              <span>{{ dados.nsu }}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="div" v-if="ingressos && ingressos.length">
      <h3>Ingressos</h3>
      <b-table :data="ingressos">
        <b-table-column field="data" label="Data" v-slot="props">
          {{ props.row.data | maskDate }}
        </b-table-column>

        <b-table-column field="tipo" label="Tipo" centered v-slot="props">
          <span class="is-capitalized">
            {{ props.row.tipo }}
          </span>
        </b-table-column>

        <b-table-column field="parque" label="Parque" centered v-slot="props">
          <span class="is-capitalized">
            {{ props.row.parque ? props.row.parque.nome : '' }}
          </span>
        </b-table-column>

        <b-table-column field="quantidade" label="Quantidade" centered v-slot="props">
          {{ props.row.quantidade }}
        </b-table-column>
      </b-table>
    </div>

    <div v-if="hospedagens && hospedagens.length">
      <h3>Hospedagens</h3>
      <b-table :data="hospedagens">
        <b-table-column field="data" label="Data" v-slot="props">
          {{ props.row.data | maskDate }}
        </b-table-column>

        <b-table-column field="hotel" label="Hotel" centered v-slot="props">
          <span class="is-capitalized">
            {{ getHotelName(props.row) }}
          </span>
        </b-table-column>
      </b-table>
    </div>

    <div class="notification is-warning">
      <h5 class="title is-4">Política de compra</h5>
      <ol type="1" class="politica-compra">
        <li>
          O reagendamento dos ingressos devem ser notificados através do whats app (17)98187-0050, com período mínimo de 7 dias antecedente a data da
          visita ao parque;
        </li>
        <li>
          O reagendamento só pode ser para o período de segunda a sexta feira, com data limite de troca de até 30 dias do ingresso. Não reagendaremos
          para os finais de semana;
        </li>
        <li>
          Uma vez retirados os ingressos, não haverá devolução de valores em nenhuma hipótese;
        </li>
        <li>
          O ingresso terá validade somente para os dias informados na realização da compra;
        </li>
        <li>
          Não é permitido a revenda dos ingressos para terceiros;
        </li>
        <li>
          A compra somente so é efetivada após a confirmação do pagamento;
        </li>
        <li>
          Não aceitamos compras com cartão de crédio virtual
        </li>
        <li>
          Compras realizadas com cartão de credito, sera obrigatório a apresentação do cartão de crédito fisico e documento (CNH ou RH) com foto para
          a retirada dos ingressos;
        </li>
        <li>
          Não haverá devolução ou reembolso de valores e ingressos por motivo de falta de energia, disturbios, chuvas ou condições climáticas
          desfavoráveis, ocorrências especiais ou qualquer fator que possa a vir a projudicar os usuários, dos quais a empresa intermediária não tenha
          dado causa do concorrido;
        </li>
        <li>
          O OlimpiaParques é mera intermediária na comercialização dos ingressos e não se responsábiliza pela não apresentação dos documentos
          necessários para utilização do parque;
        </li>
        <li>
          A visita poderá ser cancelada, com restituição (estorno) do valor pago, desde que que solicitado pelo whats (17)98187-0050, com antecedencia
          minima a 7 dias da data da visita. Nesta hipótese, sera retido o percentual de 30% do valor total da compra a titulo de despesas e taxas
          adminitrativas;
        </li>
      </ol>
    </div>

    <div class="notification is-info">
      <h5 class="title is-4">Retirada dos Ingressos</h5>
      <ol type="1" class="retirada-ingresso">
        <li>
          Os ingressos deverão ser retirados no Hotel Savana, Rua David de Oliveira, 337 - Centro - Olimpia , das 8h às 11h, todos os dias.
        </li>

        <li>
          Caso não consiga retirar o ingresso no horário de retirada, entre em contato através do telefone (17) 98187-0050
        </li>
      </ol>
    </div>

    <div class="notification is-danger">
      <h5 class="title is-4">Covid-19 - Passaporte da vacinação</h5>
      <ol type="1" class="politica-compra">
        <li>
          Será exigido apresentação do cartão de vacinação contra o Covid-19 no formato físico ou digital do ConectSus ou apresentação do teste
          negativo do Covid -19, tendo o exame sido realizado com no máximo 48 horas de antecedência.
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrintAcompanhamentoPedido',
  props: {
    numeroPedido: {
      type: [String, Number],
      required: true,
    },
    dados: {
      type: Object,
      required: true,
    },
    ingressos: {
      type: Array,
      required: true,
    },
    hospedagens: {
      type: Array,
      required: true,
    },
    hoteis: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getHotelName({ hotelId }) {
      const hotel = this.hoteis.find(h => h.id === hotelId);

      return hotel?.nome;
    },
  },
};
</script>
