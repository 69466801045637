<template>
  <section class="section" id="acompanhamento-pedido">
    <h3 class="title is-3 has-text-primary has-text-centered">
      Pedido de Compra de Ingressos
    </h3>
    <h4 class="title is-4 has-text-primary has-text-centered">Pedido #{{ numeroPedido }}</h4>
    <div class="columns is-multiline">
      <div class="column is-half">
        <b-field label="Nome">
          <b-input name="nome" v-model="venda.nome" readonly />
        </b-field>
      </div>
      <div class="column is-half">
        <b-field label="CPF">
          <the-mask mask="###.###.###-##" class="input" type="tel" name="cpf" aria-label="CPF" v-model="venda.cpf" readonly />
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half">
        <b-field label="Telefone">
          <the-mask
            :mask="['(##) ####-####', '(##) #####-####']"
            class="input"
            type="tel"
            name="telefone"
            aria-label="Telefone"
            v-model="venda.telefone"
            readonly
          />
        </b-field>
      </div>
      <div class="column">
        <b-field label="Status do Pagamento">
          <b-radio-button v-if="venda.pago" v-model="venda.pago" :native-value="true" type="is-success" selected style="cursor: default">
            <b-icon icon="check"></b-icon>
            <span>Pago</span>
          </b-radio-button>

          <b-radio-button v-else v-model="venda.pago" :native-value="false" type="is-danger" selected style="cursor: default">
            <b-icon icon="close"></b-icon>
            <span>Não Realizado</span>
          </b-radio-button>
        </b-field>
      </div>
    </div>
    <div class="columns is-multiline">
      <div class="column is-one-quarter">
        <b-field v-if="venda.pago" label="Forma de Pagamento">
          <b-input name="formaPagamento" v-model="venda.formaPagamento" readonly />
        </b-field>
      </div>

      <div class="column is-one-quarter">
        <b-field v-if="venda.pago" label="Nome cartão">
          <b-input name="nomeCartao" v-model="venda.nomeCartao" readonly />
        </b-field>
      </div>

      <div class="column is-one-quarter">
        <b-field v-if="venda.pago" label="Número cartão">
          <b-input name="numeroCartao" v-model="venda.numeroCartao" readonly />
        </b-field>
      </div>

      <div class="column is-one-quarter">
        <b-field v-if="venda.pago" label="Código do Pagamento">
          <b-input name="codigo" aria-label="Código" v-model="venda.nsu" readonly />
        </b-field>
      </div>
    </div>
    <div class="columns is-vcentered is-flex">
      <div class="column acoes-pedido">
        <b-button type="is-primary" expanded @click="imprimir">
          Imprimir
        </b-button>
        <router-link
          v-if="!venda.pago"
          tag="button"
          :to="`/pagamento-avulso/${this.id}`"
          class="button is-primary is-fullwidth"
          style="margin-top: 10px"
          @click.native="$scrollToTop()"
        >
          Realizar Pagamento
        </router-link>
        <b-button type="is-primary" expanded style="margin-top: 10px" v-if="podeConfirmarRetirada">
          Confirmar Retirada
        </b-button>
      </div>
      <PrintAcompanhamentoPedido :dados="venda" :numero-pedido="numeroPedido" :ingressos="ingressos" :hospedagens="hospedagens" :hoteis="hoteis" />
    </div>

    <div class="column" v-if="ingressos && ingressos.length">
      <h4 class="title is-4 has-text-centered has-text-primary">Ingressos</h4>
      <b-table :data="ingressos" :loading="isLoading" hoverable striped bordered>
        <b-table-column field="data" label="Data" v-slot="props">
          {{ props.row.data | maskDate }}
        </b-table-column>

        <b-table-column field="tipo" label="Tipo" centered v-slot="props">
          <span class="is-capitalized">
            {{ props.row.tipo }}
          </span>
        </b-table-column>

        <b-table-column field="parque" label="Parque" centered v-slot="props">
          <span class="is-capitalized">
            {{ props.row.parque ? props.row.parque.nome : '' }}
          </span>
        </b-table-column>

        <b-table-column field="quantidade" label="Quantidade" centered v-slot="props">
          {{ props.row.quantidade }}
        </b-table-column>
      </b-table>
    </div>

    <div class="column" v-if="hospedagens && hospedagens.length">
      <h4 class="title is-4 has-text-centered has-text-primary">Hospedagens</h4>
      <b-table :data="hospedagens" :loading="isLoading" hoverable striped bordered>
        <b-table-column field="data" label="Data" v-slot="props">
          {{ props.row.data | maskDate }}
        </b-table-column>

        <b-table-column field="hotel" label="Hotel" centered v-slot="props">
          <span class="is-capitalized">
            {{ getHotelName(props.row) }}
          </span>
        </b-table-column>
      </b-table>
    </div>

    <div class="column">
      <router-link to="/consultar-pedido" tag="button" class="button is-primary">
        Voltar
      </router-link>
    </div>

    <div class="notification is-warning">
      <h5 class="title is-4">Política de compra</h5>
      <ol type="1" class="politica-compra">
      <li>
          O reagendamento dos ingressos devem ser notificados através do whats app (17)98187-0050, com período mínimo de 7 dias antecedente a data da visita ao parque;
        </li>
        <li>
          O reagendamento só pode ser para o período de segunda a sexta feira, com data limite de troca de até 30 dias do ingresso. Não reagendaremos para os finais de semana;
        </li>
        <li>
          Uma vez retirados os ingressos, não haverá devolução de valores em nenhuma hipótese;
        </li>
        <li>
          O ingresso terá validade somente para os dias informados na realização da compra;
        </li>
        <li>
          Não é permitido a revenda dos ingressos para terceiros;
        </li>
        <li>
          A compra somente so é efetivada após a confirmação do pagamento;
        </li>
          <li>
          Não aceitamos compras com cartão de crédio virtual
        </li>
        <li>
          Compras realizadas com cartão de credito, sera obrigatório a apresentação do cartão de crédito fisico e documento (CNH ou RH) com foto para a retirada dos ingressos;
        </li>
        <li>
          Não haverá devolução ou reembolso de valores e ingressos por motivo de falta de energia, disturbios, chuvas ou condições climáticas desfavoráveis, ocorrências especiais ou qualquer fator que possa a vir a projudicar os usuários, dos quais a empresa intermediária não tenha dado causa do concorrido;
        </li>
        <li>
          O OlimpiaParques é mera intermediária na comercialização dos ingressos e não se responsábiliza pela não apresentação dos documentos necessários para utilização do parque;
        </li>
        <li>
          A visita poderá ser cancelada, com restituição (estorno) do valor pago, desde que que solicitado pelo whats (17)98187-0050, com antecedencia minima a 7 dias da data da visita. Nesta hipótese, sera retido o percentual de 30% do valor total da compra a titulo de despesas e taxas adminitrativas;
        </li>
      </ol>
    </div>

    <div class="notification is-info">
      <h5 class="title is-4">Retirada dos Ingressos</h5>
      <ol type="1" class="politica-compra">
        <li>
          Os ingressos deverão ser retirados no Hotel Savana, Rua David de Oliveira, 337 - Centro - Olimpia , das 8h às 11h, todos os dias.
        </li>

        <li>
          Caso não consiga retirar o ingresso no horário de retirada, entre em contato através do telefone (17) 98187-0050
        </li>
      </ol>
    </div>

    <div class="notification is-danger">
      <h5 class="title is-4">Covid-19 - Passaporte da vacinação</h5>
      <ol type="1" class="politica-compra">
        <li>
        Será exigido apresentação do cartão de vacinação contra o Covid-19 no formato físico ou digital do ConectSus ou apresentação do teste negativo do Covid -19, tendo o exame sido realizado com no máximo 48 horas de antecedência.
        </li>
      </ol>
    </div>


  </section>
</template>

<script>
import dayjs from 'dayjs';
import { Printd } from 'printd';
import PrintAcompanhamentoPedido from './PrintAcompanhamentoPedido';
import vendaService from '@/services/vendaService';
import hoteisService from '@/services/hoteisService';
import parqueService from '@/services/parqueService';

export default {
  name: 'AcompanhamentoPedido',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  components: {
    PrintAcompanhamentoPedido,
  },
  data() {
    return {
      numeroPedido: this.id,
      isLoading: false,
      podeConfirmarRetirada: false,
      venda: {},
      hoteis: [],
    };
  },
  computed: {
    ingressos() {
      const detalhes = this.venda.detalhes || [];

      return detalhes.sort((a, b) => (dayjs(a.data).isAfter(dayjs(b.data)) ? 1 : -1));
    },
    hospedagens() {
      return this.venda.hospedagens || [];
    },
  },
  async created() {
    this.isLoading = true;

    await this.getHoteis();
    const { data: parques } = await parqueService.get();

    vendaService
      .getById(this.id)
      .then(response => {
        const { data: venda } = response;
        venda.detalhes = venda.detalhes.map(venda => {
          venda.parque = parques.find(p => p.id === venda.parqueId);
          return venda;
        });
        this.venda = venda;

        const [pagamento] = response.data.pagamentos.slice(-1);
        this.venda.nsu = pagamento ? pagamento.nsu : '--';
        this.venda.formaPagamento = pagamento != null && pagamento.formaPagamentoId ? this.getFormaPagamento(pagamento.formaPagamentoId) : '--';
        this.venda.numeroCartao = pagamento != null && pagamento.numero ? pagamento.numero.replace(/\d{12}/g, '****') : '--';
        this.venda.nomeCartao = pagamento != null && pagamento.nome ? pagamento.nome : '--';

        if (pagamento.formaPagamentoId === 3) this.venda.nsu = pagamento != null && pagamento.mercadoPagoId ? pagamento.mercadoPagoId : '--';
      })
      .finally(() => (this.isLoading = false));

    if (this.$route.query['retirada-de-ingressos']) {
      this.podeConfirmarRetirada = true;
    }
  },
  methods: {
    getFormaPagamento(formaPagamentoId) {
      let formaDescricao = 'Crédito';

      if (formaPagamentoId === 2) {
        formaDescricao = 'Débito';
      }

      if (formaPagamentoId === 3) {
        formaDescricao = 'Mercado Pago';
      }

      return formaDescricao;
    },
    async getHoteis() {
      await hoteisService.get().then(({ data }) => {
        this.hoteis = data;
      });
    },
    getHotelName({ hotelId }) {
      const hotel = this.hoteis.find(h => h.id === hotelId);

      return hotel?.nome;
    },
    imprimir() {
      const cssText = `
        #print-acompanhamento-pedido {
          padding: 20px;
        }

        * {
          font-family: sans-serif;
        }

        h1, h2, h3, h4, h5, h6 {
          color: #ff6d01;
          font-weight: bold;
          text-align: center;
        }

        .is-capitalized {
          text-transform: capitalize;
        }

        table, th, td {
          border: 1px solid #000;
        }

        table {
          width: 100%;
          border-collapse: collapse;
        }

        .table th,
        .table td {
          padding: 4px;
        }

        td:nth-of-type(1),td:nth-of-type(2),td:nth-of-type(3),td:nth-of-type(4),td:nth-of-type(5) {
          text-align: center;
        }

        .container-dados {
          font-size: 14px;
        }

        .logo-image {
          width: 100px;
        }
      `;

      const print = new Printd();
      print.print(document.getElementById('print-acompanhamento-pedido'), [cssText]);
    },
  },
};
</script>

<style>
#acompanhamento-pedido {
  max-width: 1100px;
  margin: auto;
}

.acoes-pedido {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.valor-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px 0px;
  font-size: 20px;
}

#print-acompanhamento-pedido {
  display: none;
}
</style>
